<template>
  <div class="task-form">
    <div class="task-form-header">
      <div class="modal-title">{{ taskId ? "Editar" : "Criar" }} Tarefa</div>

      <i v-if="!blockCancel" @click="$emit('close')" class="icon-close-circle-outline" />
    </div>

    <validation-observer
      v-if="!hasTaskRequest"
      class="form-holder nice-Vscroll"
      ref="formValidation"
    >
      <validation-provider
        #default="{ errors }"
        name="Oportunidade"
        rules="required"
        vid="localOpportunity"
        style="z-index: 101"
      >
        <small class="v-err text-danger mb-1">{{ errors[0] }}</small>

        <task-opportunity-selector
          @open-opportunity="handleOpenOpportunity()"
          @open-dialog="handleOpenDialog()"
          @single-voip="handleSingleVoipCall()"
          @open-call="handleOpenCall()"
          :isEdit="!!taskId"
          :lock-opportunity="!!opportunity"
          v-model="localOpportunity"
        />
      </validation-provider>

      <validation-provider #default="{ errors }" name="Responsável" rules="required" vid="user_id">
        <small class="v-err text-danger mb-1">{{ errors[0] }}</small>

        <div class="field-label">
          <label v-show="user_id" for="task_user">Responsável</label>

          <v-select
            :options="allUsers"
            :reduce="(option) => option.id"
            :loading="hasUsersRequest"
            :disabled="role == 'salesRep' || unchangedTask?.is_registry"
            :clearable="false"
            id="task_user"
            label="name"
            class="labeled-vselect"
            placeholder="Responsável"
            v-model="user_id"
          />
        </div>
      </validation-provider>

      <line-component color="#C6C6C6" />

      <b-button
        v-if="taskId && !is_registry && !is_schedule_completed"
        @click="completeSchedule()"
        class="complete-task-button"
      >
        <feather-icon icon="CheckCircleIcon" size="20" />

        <div>Completar Tarefa</div>
      </b-button>

      <div class="task-form-content">
        <div class="field-label">
          <label v-show="name" for="name">Nome</label>

          <input class="task-form-input" placeholder="Nome da Tarefa" v-model="name" />
        </div>

        <validation-provider #default="{ errors }" name="Ação" rules="required" vid="action">
          <small class="v-err text-danger mb-1">{{ errors[0] }}</small>

          <div class="field-label">
            <label v-show="action" for="action">Ação</label>

            <v-select
              :options="actions"
              :reduce="(option) => option.id"
              :loading="hasUsersRequest"
              :clearable="false"
              id="action"
              label="name"
              class="labeled-vselect"
              placeholder="Ação"
              v-model="action"
            />
          </div>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Forma de Atendimento"
          rules="required"
          vid="ended_type"
        >
          <small class="v-err text-danger mb-1">{{ errors[0] }}</small>

          <div class="field-label">
            <label v-show="ended_type" for="ended_type">Forma de Atendimento</label>

            <v-select
              :options="ended_types"
              :reduce="(option) => option.id"
              :loading="hasUsersRequest"
              :clearable="false"
              id="ended_type"
              label="name"
              class="labeled-vselect"
              placeholder="Forma de Atendimento"
              v-model="ended_type"
            />
          </div>
        </validation-provider>

        <div class="check-holder">
          <b-form-checkbox
            @input="setTaskTime()"
            :disabled="registerOnly || taskId"
            v-model="is_registry"
          />

          <div class="check-label">Esta tarefa é um registro</div>
        </div>

        <div class="date-time-content">
          <validation-provider #default="{ errors }" name="Data" rules="required" vid="date">
            <small class="text-danger">{{ errors[0] }}</small>

            <div class="field-label">
              <label v-show="date" for="date">Data</label>

              <date-picker
                id="date"
                class="task-date"
                placeholder="Data"
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                :disabled="is_registry"
                v-model="date"
              />
            </div>
          </validation-provider>

          <validation-provider #default="{ errors }" name="Horário" vid="time" rules="required">
            <small class="text-danger">{{ errors[0] }}</small>

            <div class="field-label">
              <label v-show="time" for="time">Horário</label>

              <date-picker
                id="time"
                class="task-date"
                type="time"
                placeholder="Horário"
                format="HH:mm"
                value-type="HH:mm"
                :disabled="is_registry"
                v-model="time"
              />
            </div>
          </validation-provider>
        </div>

        <jitsi-button
          @jitsiObservation="handleJitsiObservation($event)"
          :localOpportunity="localOpportunity"
          :taskDate="date"
          :taskTime="time"
        />

        <div class="field-label">
          <label v-show="description" for="description"> Observação </label>

          <textarea
            id="description"
            maxlength="1000"
            class="observation"
            rows="4"
            max-rows="5"
            placeholder="💬 Observações"
            v-model="description"
          />
        </div>
      </div>

      <div
        v-if="taskId"
        @click="deleteTask()"
        class="task-form-collapse"
        style="border-color: #ff3159"
      >
        <div class="task-title active" style="margin: 0; color: #ff3159">
          <feather-icon icon="Trash2Icon" size="20" />

          <div>Deletar Tarefa</div>
        </div>
      </div>
    </validation-observer>

    <div v-else class="spinner-holder">
      <b-spinner />
    </div>

    <div class="bottom-menu">
      <b-button
        v-if="!blockCancel"
        @click="$emit('close')"
        :disabled="hasSaveRequest"
        variant="light"
      >
        Cancelar
      </b-button>

      <b-button @click="saveTask()" :disabled="hasTaskRequest" variant="primary">
        <b-spinner v-if="hasSaveRequest" small />

        <div v-else>{{ confirm_button_text }}</div>
      </b-button>
    </div>

    <b-modal id="bv-task-form-opportunity-tabs" class="modal-dialog" hide-footer hide-header>
      <opportunity-tabs
        v-if="localOpportunity"
        @close="$bvModal.hide('bv-task-form-opportunity-tabs')"
        :opportunity_id="localOpportunity.id"
        :task-event="taskOpportunityEvent"
      />
    </b-modal>

    <b-modal id="bv-task-form-task-form" class="modal-dialog" hide-footer hide-header>
      <task-form
        @saved="handleTaskSaved()"
        @close="$bvModal.hide('bv-task-form-task-form')"
        :opportunity="localOpportunity"
        :registerOnly="true"
      />
    </b-modal>
  </div>
</template>

<script>
import { BButton, BFormCheckbox, BFormTextarea, BSpinner } from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import moment from "moment";
import "vue2-datepicker/index.css";

import JitsiButton from "@/views/forms/components/JitsiButton.vue";
import Line from "@/views/components/Line.vue";
import { openPhoneChannel } from "@/services/callService";
import OpportunityTabs from "@/views/forms/OpportunityTabs.vue";
import TaskOpportunitySelector from "@/views/forms/components/TaskOpportunitySelector.vue";

export default {
  components: {
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    DatePicker,
    vSelect,
    ValidationObserver,
    ValidationProvider,

    JitsiButton,
    OpportunityTabs,
    TaskOpportunitySelector,

    "line-component": Line,

    TaskForm: () => import("@/views/forms/TaskForm.vue"),
  },

  props: {
    taskId: {
      type: Number | null,
      default: null,
    },

    opportunity: {
      type: Object,
      default: null,
    },

    registerOnly: {
      type: Boolean,
      default: false,
    },

    presetDate: {
      type: String,
      default: null,
    },

    blockCancel: {
      type: Boolean,
      default: false,
    },

    openingEvent: {
      type: String | null,
      default: null,
    },
  },

  data() {
    return {
      unchangedTask: null,

      localOpportunity: this.opportunity || null,
      user_id: null,

      name: null,
      is_registry: false,
      action: null,
      ended_type: null,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm"),
      description: null,

      allUsers: [],

      hasTaskRequest: false,
      hasUsersRequest: false,
      hasActionTypesRequest: false,
      hasEndedTypesRequest: false,
      hasSaveRequest: false,

      taskOpportunityEvent: null,

      required,

      toasterConfig: {
        title: `Sucesso`,
        autoHideDelay: 1500,
        variant: "success",
        toaster: "b-toaster-top-left",
        solid: true,
      },

      openingEvents: {
        conclude: () => {
          this.completeSchedule();
        },

        delete: () => {
          this.deleteTask();
        },
      },
    };
  },

  computed: {
    user() {
      return this.$store.getters["app/getUserData"];
    },

    role() {
      return this.$store.getters["app/getUserRole"];
    },

    actions() {
      return this.$store.getters["calendarStore/getActions"];
    },

    ended_types() {
      return this.$store.getters["calendarStore/getEndedTypes"];
    },

    is_schedule_completed() {
      return this.unchangedTask?.status == "Completed";
    },

    confirm_button_text() {
      if (!this.taskId) return "Criar";

      if (!this.unchangedTask?.is_registry && this.is_schedule_completed) return "Reabrir";

      return "Atualizar";
    },
  },

  watch: {
    action: function (newSelected, oldSelected) {
      if (newSelected != oldSelected) {
        this.setQuickEndedType();
      }
    },
  },

  created() {
    if (this.taskId) {
      this.fetchTask();
    } else {
      this.setDefaultValues();
    }

    this.fetchAllUsers();
    this.fetchActionTypes();
    this.fetchEndedTypes();
  },

  methods: {
    setDefaultValues() {
      this.user_id = this.user.id;

      this.is_registry = this.registerOnly;

      if (this.presetDate) this.date = moment(this.presetDate).format("YYYY-MM-DD");
    },

    setTaskTime() {
      if (this.is_registry) {
        this.date = moment().format("YYYY-MM-DD");
        this.time = moment().format("HH:mm");
      }
    },

    setQuickEndedType() {
      const selectedTaskType = this.actions.find((type) => type.id == this.action);

      if (!selectedTaskType) return;

      const endedType = this.ended_types.find(
        (type) => type.id == selectedTaskType.default_interaction_type_id
      );

      this.ended_type = endedType?.id;
    },

    setTaskValues(data) {
      this.localOpportunity = data.opportunity;
      this.localOpportunity.contact = data.contact;

      this.user_id = data.user_id;

      this.name = data.name;
      this.is_registry = data.is_registry;
      this.action = data.task_type_id;
      this.ended_type = data.interaction_type_id;
      this.date = moment(data.start_timestamp).format("YYYY-MM-DD");
      this.time = moment(data.start_timestamp).format("HH:mm");
      this.description = data.description;

      this.handleOpeningEvent();
    },

    getRegisterPayload() {
      return {
        name: this.name,
        user_id: this.user_id,
        parent_id: this.localOpportunity.id,
        parent_type: "Opportunity",
        task_type_id: this.action,
        interaction_type_id: this.ended_type,
        description: this.description,
        start_timestamp: moment().format("YYYY-MM-DD HH:mm"),
        is_registry: true,
        ended_scheduled: false,
      };
    },

    getSchedulePayload() {
      return {
        name: this.name,
        user_id: this.user_id,
        parent_id: this.localOpportunity.id,
        parent_type: "Opportunity",
        task_type_id: this.action,
        interaction_type_id: this.ended_type,
        start_timestamp: `${this.date} ${this.time}`,
        description: this.description,
        is_registry: false,
        status: "NotStarted",
      };
    },

    handleOpeningEvent() {
      if (!this.openingEvent) return;

      this.openingEvents[this.openingEvent]();
    },

    handleJitsiObservation(meetingMessage) {
      if (!this.description) {
        this.description = meetingMessage;
      } else {
        this.description += meetingMessage;
      }
    },

    handleOpenOpportunity() {
      this.taskOpportunityEvent = null;

      this.$bvModal.show("bv-task-form-opportunity-tabs");
    },

    handleOpenDialog() {
      this.taskOpportunityEvent = "dialog";

      this.$bvModal.show("bv-task-form-opportunity-tabs");
    },

    handleSingleVoipCall() {
      this.taskOpportunityEvent = "voip";

      this.$bvModal.show("bv-task-form-opportunity-tabs");
    },

    handleOpenCall() {
      openPhoneChannel(this.localOpportunity.contact.phone_number);

      this.$bvModal.show("bv-task-form-task-form");
    },

    handleTaskSaved() {
      this.$bvModal.hide("bv-task-form-task-form");

      this.$emit("saved");
      this.$emit("close");
    },

    fetchActionTypes() {
      if (this.actions.length) return;

      this.hasActionTypesRequest = true;

      this.$store.dispatch("calendarStore/fetchTaskTypes").finally(() => {
        this.hasActionTypesRequest = false;
      });
    },

    fetchEndedTypes() {
      if (this.ended_types.length) return;

      this.hasEndedTypesRequest = true;

      this.$store.dispatch("calendarStore/fetchEndedTypes").finally(() => {
        this.hasEndedTypesRequest = false;
      });
    },

    fetchAllUsers() {
      this.hasUsersRequest = true;

      const params = [
        {
          query: "limit",
          param: 500,
        },
      ];

      this.$store
        .dispatch("userStore/list", this.$service.formatQueryParam(params))
        .then((response) => {
          this.allUsers = response.data;
        })
        .finally(() => {
          this.hasUsersRequest = false;
        });
    },

    fetchTask() {
      this.hasTaskRequest = true;

      this.$store
        .dispatch("calendarStore/fetchOneTask", this.taskId)
        .then((response) => {
          this.unchangedTask = response.data;

          this.setTaskValues(response.data);
        })
        .finally(() => {
          this.hasTaskRequest = false;
        });
    },

    assignOpportunity() {
      const payload = {
        user_id: this.user_id,
        opportunity_id: this.localOpportunity.id,
      };

      return this.$store.dispatch("opportunityStore/assignOpportunity", payload);
    },

    registerTask() {
      const payload = this.getRegisterPayload();

      if (this.taskId) {
        payload.id = this.taskId;

        return this.$store.dispatch("calendarStore/updateTask", payload).then((response) => {
          this.$bvToast.toast("Registro atualizado com sucesso!", this.toasterConfig);

          return response;
        });
      }

      return this.$store.dispatch("calendarStore/createTask", payload).then((response) => {
        this.$bvToast.toast("Registro criado com sucesso!", this.toasterConfig);

        return response;
      });
    },

    scheduleTask() {
      const payload = this.getSchedulePayload();

      if (this.taskId) {
        payload.id = this.taskId;

        payload.status = "NotStarted";

        return this.$store.dispatch("calendarStore/updateTask", payload).then((response) => {
          this.$bvToast.toast("Agendamento atualizado com sucesso!", this.toasterConfig);

          return response;
        });
      }

      return this.$store.dispatch("calendarStore/createTask", payload).then((response) => {
        this.$bvToast.toast("Agendamento criado com sucesso!", this.toasterConfig);

        return response;
      });
    },

    completeSchedule() {
      if (this.hasSaveRequest) return;

      const formRefs = this.$refs.formValidation;

      formRefs.validate().then((success) => {
        if (!success) return;

        this.hasSaveRequest = true;

        const payload = {
          id: this.taskId,
          status: "Completed",
          ended_at: moment().format("YYYY-MM-DD HH:mm"),
          interaction_type_id: this.ended_type,
        };

        this.$store
          .dispatch("calendarStore/endTask", payload)
          .then(() => {
            this.$bvToast.toast("Tarefa concluída com sucesso!", this.toasterConfig);

            setTimeout(() => {
              this.$emit("saved");
              this.$emit("close");
            }, 2000);
          })
          .finally(() => {
            setTimeout(() => (this.hasSaveRequest = false), 2000);
          });
      });
    },

    saveTask() {
      if (this.hasSaveRequest) return;

      const formRefs = this.$refs.formValidation;

      formRefs.validate().then((success) => {
        if (!success) return;

        this.hasSaveRequest = true;

        let promises = [];

        if (this.is_registry) promises.push(this.registerTask());

        if (!this.is_registry) promises.push(this.scheduleTask());

        if (this.user_id != this.localOpportunity.user_id) promises.push(this.assignOpportunity());

        Promise.all(promises)
          .then(() => {
            setTimeout(() => {
              this.$emit("saved");
              this.$emit("close");
            }, 2000);
          })
          .finally(() => {
            setTimeout(() => (this.hasSaveRequest = false), 2000);
          });
      });
    },

    deleteTask() {
      let sure = confirm("Tem certeza disso?");

      if (!sure) return;

      if (this.hasSaveRequest) return;

      this.hasSaveRequest = true;

      this.$store
        .dispatch("calendarStore/delete", this.taskId)
        .then(() => {
          this.$bvToast.toast("Tarefa deletada com sucesso!", this.toasterConfig);

          setTimeout(() => {
            this.$emit("deleted");
            this.$emit("close");
          }, 2000);
        })
        .finally(() => {
          setTimeout(() => (this.hasSaveRequest = false), 2000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100dvh;
  width: 100%;
  overflow: hidden;

  .task-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid #ececec;
    color: #31333f;

    .modal-title {
      font-size: 15px;
      font-weight: 600;
    }

    .icon-close-circle-outline {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .form-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1.5rem;
    gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    .field-label {
      position: relative;

      label {
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        top: -11px;
        left: 15px;
        z-index: 100;
        padding: 3px;
        border-radius: 5px;
        background: #ffffff;
        color: #b1b1b1;
      }

      :deep(.labeled-vselect) {
        .vs__dropdown-toggle {
          padding: 5px 5px 9px 5px;
          background: #ffffff !important;
        }

        .vs__selected {
          color: #6e6b7b;
        }

        .vs__search {
          background: #ffffff !important;
        }
      }

      .task-form-input {
        height: 42px;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #c6c6c6;
        outline: none;
        color: #6e6b7b;
      }
    }

    .complete-task-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 5px;
      border-radius: 5px;
      border: none;
      background: #046af3 !important;
      color: #ffffff !important;
      cursor: pointer;
    }

    .task-form-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;

      .observation {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #c6c6c6;
        outline: none;
        color: #6e6b7b;
      }

      .observation::placeholder {
        margin: 0 auto;
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translateX(35%) translateY(25%);
        overflow: visible;
      }

      .check-holder {
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 5px;
        border-radius: 5px;
        background: #f5f5f5;
      }

      .date-time-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        width: 100%;
        gap: 10px;

        .task-date {
          width: 100%;
        }

        :deep(.task-date) {
          .mx-input {
            height: 42px;
            border: 1px solid #c6c6c6;
            border-radius: 5px;
            color: #6e6b7b;
            box-shadow: none;
          }
        }

        :deep(.task-disabled) {
          .mx-input {
            background: #ffffff !important;
          }
        }
      }
    }

    .task-form-collapse {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      gap: 10px;
      border-radius: 5px;
      border-width: 1px 1px 1px 5px;
      border-style: solid;
      user-select: none;
      cursor: pointer;

      .task-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        gap: 5px;
      }

      .active {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .form-holder > * {
    flex-shrink: 0;
  }

  .spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .bottom-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    margin-top: auto;
    border-top: 1px solid #ececec;
    color: #000000;
    background: #ffffff;
  }
}
</style>
