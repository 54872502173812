<template>
  <div class="faq-component">
    <div class="faq-modal-body">
      <i class="icon-close-circle-outline close-button-style" @click="closeFaqModal" />

      <img
        class="image-style"
        src="../../assets/images/icons/warning-calendar.svg"
        alt="warning-icon"
      />

      <div style="font-size: 22px; color: #000000">Atualização importante!</div>

      <div style="color: #046af3">Atualizado em {{ warning.created_at }}</div>

      <div style="line-height: 24px; color: #686868">{{ warning.message }}</div>

      <div v-if="warning.iframeInfo" class="iframe-holder">
        <iframe
          :title="warning.iframeInfo.title"
          :src="warning.iframeInfo.src"
          width="100%"
          height="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>

      <div v-if="warning.carouselInfo" class="carousel-holder">
        <div class="carousel-container">
          <feather-icon
            icon="ChevronLeftIcon"
            size="30"
            @click="changeSlide(-1)"
            class="cta-icon"
          />

          <img
            :src="warning.carouselInfo[currentCarouselIndex].src"
            :alt="warning.carouselInfo[currentCarouselIndex].description"
          />

          <feather-icon
            icon="ChevronRightIcon"
            size="30"
            @click="changeSlide(+1)"
            class="cta-icon"
          />
        </div>

        <div class="carousel-dots">
          <feather-icon
            v-for="(dot, index) in warning.carouselInfo"
            @click="currentCarouselIndex = index"
            :style="{ fill: currentCarouselIndex === index ? '#046af3' : '#6e6b7b' }"
            :key="index"
            icon="CircleIcon"
            class="cta-icon"
            size="20"
          />
        </div>

        <div class="carousel-description">
          {{ warning.carouselInfo[currentCarouselIndex].description }}
        </div>
      </div>

      <b-button @click="closeFaqModal" style="width: 140px; margin-top: 5px" variant="primary">
        Ok
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: { BButton },

  props: {
    warning: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      currentModalPage: "",

      currentCarouselIndex: 0,
    };
  },

  computed: {},

  methods: {
    closeFaqModal() {
      this.$store.commit("newsWarning/CHANGE_NEWS_MODAL_CONDITION", false);
    },

    changeSlide(value) {
      if (this.currentCarouselIndex + value < 0) {
        this.currentCarouselIndex = this.warning.carouselInfo.length - 1;
      } else if (this.currentCarouselIndex + value >= this.warning.carouselInfo.length) {
        this.currentCarouselIndex = 0;
      } else {
        this.currentCarouselIndex += value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  background: #00000066;
  overflow: hidden;

  .faq-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: 16px;
    height: 90dvh;
    width: 100vw;
    max-width: 750px;
    padding: 1rem 2.5rem;
    gap: 15px;
    border-radius: 20px;
    background: white;
    border: solid 1px #9c9c9c;
    overflow-y: overlay;
    overflow-x: hidden;

    .close-button-style {
      position: sticky;
      font-size: 20px;
      top: 1rem;
      left: calc(100% - 2.5rem);
      width: 25px;
      color: #9c9c9c;
      cursor: pointer;
    }

    .image-style {
      height: 73px;
      width: 71px;
    }

    .close-button-style:hover {
      color: #cd364a;
    }

    .iframe-holder {
      width: 100%;
      height: 300px;
      overflow: hidden;
    }

    .carousel-holder {
      display: flex;
      flex-direction: column;
      width: 650px;
      gap: 10px;

      .cta-icon {
        cursor: pointer;
      }

      .carousel-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 450px;
        gap: 5px;
        overflow: hidden;
        user-select: none;

        img {
          height: auto;
          max-height: 100%;
          max-width: calc(100% - 70px);
        }
      }

      .carousel-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
      }

      .carousel-description {
        text-align: center;
      }
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      margin: 10px 0px;
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 10px #c8c8c8;
    }
  }

  @media (max-width: 800px) {
    .faq-modal-body {
      height: 100dvh;
      width: 100dvw;
      padding: 0.5rem 1rem;
      border-radius: 0px;
      min-width: auto;

      .carousel-holder {
        width: 100%;

        .carousel-container {
          height: 300px;
        }
      }
    }
  }
}
</style>
