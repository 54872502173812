const initialState = () => ({
  isOpen: false,

  updateTick: false,

  allNewsList: [
    {
      id: "newDefaultDate",
      created_at: "01/08/2023 - 09:00",
      expiration: "08/08/2023",
      message:
        "Agora, o nosso CRM exibe somente as oportunidades dos últimos dias no Pipeline (área inicial da plataforma). Se você deseja ver todas as oportunidades, basta filtrar a data desejada.",
    },

    {
      id: "batchMessaging",
      created_at: "27/09/2024 - 09:00",
      expiration: "10/10/2024",
      message:
        "Nova funcionalidade no CRM: Disparo de Mensagens em Massa! Agora, com apenas 3 cliques e filtros avançados, você pode enviar mensagens para todos os seus clientes de forma rápida e integrada. Veja como é simples no vídeo abaixo.",
      iframeInfo: {
        title: "Disparo de mensagens em massa CRM",
        src: "https://www.youtube.com/embed/PHw86dthVF8",
      },
    },

    {
      id: "pipeline_2.0",
      created_at: "18/09/2024 - 09:00",
      expiration: "10/04/2025",
      message: "Nova Pipeline.",
      carouselInfo: [
        {
          description: "Nova pipeline 2.0 com vários benefícios e melhorias!!",
          src: "/News/pipeline 2.0 1.png",
        },

        {
          description:
            "Aproveitamento de telas aprimorado para melhor usabilidade. Com novo design intuitivo e funcional, visualização detalhada e em lista",
          src: "/News/pipeline 2.0 2.png",
        },

        {
          description:
            "Modos de visualização flexíveis: lista compacta ou exibição completa.Filtros rápidos na Pipe, permitindo localizar informações com mais agilidade.",
          src: "/News/pipeline 2.0 3.png",
        },

        {
          description:
            "Nova função “Minhas Tarefas”, facilitando a organização do fluxo de trabalho. Atualização da Pipe e dos cards sem perda de contexto, mantendo referências e posição no fluxo e URL dinâmica que se adapta aos filtros aplicados, facilitando o compartilhamento e navegação.",
          src: "/News/pipeline 2.0 4.png",
        },

        { description: "Lista de melhorias", src: "/News/pipeline 2.0 5.png" },
      ],
    },
  ],
});

const getters = {
  getAllWarnings(state) {
    return state.allNewsList;
  },
};

const mutations = {
  CHANGE_NEWS_MODAL_CONDITION(state, condition) {
    state.isOpen = condition;
  },

  TICK_UPDATE(state) {
    state.updateTick = !state.updateTick;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
};
