<template>
  <div class="task-opp-selector">
    <div v-if="!isEdit" @click="openSelection()" class="opp-selector">
      <div v-if="value" class="overflow-text">
        <span style="color: #046af3">#{{ value.id }}</span> | {{ value.name }}
      </div>

      <div v-else style="color: #b1b1b1">Selecione uma oportunidade</div>

      <feather-icon
        v-show="value && !lockOpportunity"
        @click.stop="unsetOpportunity()"
        icon="XIcon"
        class="remove-opp"
        size="20"
      />
    </div>

    <div v-else class="opp-selected">
      <avatar
        :hasBorder="false"
        :hasStatus="false"
        :avatarLetter="$service.getFirstLetter(value.name)"
        avatarBackground="#109CF1"
        avatarColor="#FFFFFF"
        class="marginless"
      />

      <div class="texts-holder">
        <div class="overflow-text">{{ value.name }}</div>

        <div class="overflow-text" style="color: #046af3">#{{ value.id }}</div>
      </div>

      <b-dropdown id="drop-actions">
        <template #button-content>
          <feather-icon icon="ListIcon" size="18" />
        </template>

        <div class="drop-header">Selecione a ação desejada:</div>

        <b-dropdown-item @click="$emit('open-opportunity')">
          <i class="icon-user-profile" style="font-size: 18px" />

          Ver Oportunidade
        </b-dropdown-item>

        <b-dropdown-item @click="$emit('open-dialog')">
          <i class="icon-chat-ballon" style="font-size: 18px" />

          Enviar Mensagem
        </b-dropdown-item>

        <b-dropdown-item v-if="!isIOS" @click="$emit('single-voip')">
          <i class="icon-phone-chat-icon" style="font-size: 18px" />

          Realizar Ligação (Voip)
        </b-dropdown-item>

        <b-dropdown-item @click="$emit('open-call')">
          <i class="icon-phone-chat-icon" style="font-size: 18px" />

          Realizar Ligação
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-modal id="task-opp-selector-opportunity-list" hide-footer hide-header no-close-on-backdrop>
      <div class="task-opp-selector-modal">
        <div class="task-opp-selector-modal-header">
          <div class="modal-title">Selecionar Oportunidade</div>

          <i
            @click="$bvModal.hide('task-opp-selector-opportunity-list')"
            class="icon-close-circle-outline"
          />
        </div>

        <div class="list-holder nice-Vscroll" ref="formValidation">
          <div class="search-box">
            <input @input="handleSearch()" v-model="search" />

            <feather-icon
              v-show="search"
              @click.stop="clearSearch()"
              icon="XIcon"
              size="20"
              style="cursor: pointer"
            />

            <feather-icon icon="SearchIcon" size="20" />
          </div>

          <div v-if="hasOpportunityRequest" class="spinner-holder">
            <b-spinner />
          </div>

          <div v-else>
            <div
              v-for="(opp, index) in oppList"
              @click="selectOpportunity(opp)"
              :key="index"
              class="opportunity-item"
            >
              <avatar
                :hasBorder="false"
                :hasStatus="false"
                :avatarLetter="$service.getFirstLetter(opp.name)"
                avatarBackground="#109CF1"
                avatarColor="#FFFFFF"
              />

              <div class="opp-info">
                <div class="opp-name">
                  {{ opp.name }}
                </div>

                <div class="opp-workspace">#{{ opp.id }} | {{ opp.workspace.name }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom-menu">
          <b-button @click="$bvModal.hide('task-opp-selector-opportunity-list')" variant="light">
            Cancelar
          </b-button>

          <b-button @click="createNewOpportunity()" variant="primary"> Criar Nova </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="task-opp-select-create_opportunity_modal"
      class="modal-dialog"
      hide-footer
      hide-header
    >
      <create-opportunity
        @saved="fetchOpportunities(), $bvModal.hide('task-opp-select-create_opportunity_modal')"
        @close="$bvModal.hide('task-opp-select-create_opportunity_modal')"
      />
    </b-modal>
  </div>
</template>

<script>
import { BButton, BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";

import Avatar from "@/views/components/Avatar.vue";
import CreateOpportunity from "@/views/forms/CreateOpportunity.vue";

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,

    Avatar,
    CreateOpportunity,
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object | null,
      default: null,
    },

    lockOpportunity: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      oppList: [],

      hasOpportunityRequest: false,

      search: "",
      searchTimeout: null,
    };
  },

  computed: {
    role() {
      return this.$store.getters["app/getUserRole"];
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    isIOS() {
      const userAgent = navigator.userAgent;

      return /iPhone|iPad|iPod/i.test(userAgent);
    },
  },

  methods: {
    openSelection() {
      if (this.lockOpportunity) return;

      this.fetchOpportunities();

      this.$bvModal.show("task-opp-selector-opportunity-list");
    },

    selectOpportunity(opp) {
      this.$emit("input", opp);

      this.$bvModal.hide("task-opp-selector-opportunity-list");
    },

    unsetOpportunity() {
      this.$emit("input", null);
    },

    clearSearch() {
      this.search = "";

      this.handleSearch();
    },

    handleSearch() {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.fetchOpportunities();
      }, 500);
    },

    createNewOpportunity() {
      this.$bvModal.show("task-opp-select-create_opportunity_modal");
    },

    fetchOpportunities() {
      this.hasOpportunityRequest = true;

      const params = [
        { query: "limit", param: 15 },
        { query: "search", param: this.search },
      ];

      if (this.role === "salesRep") params.push({ query: "user_id", param: this.user.id });

      this.$store
        .dispatch("opportunityStore/fetch", this.$service.formatQueryParam(params))
        .then((response) => {
          this.oppList = response.data;
        })
        .finally(() => {
          this.hasOpportunityRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-opp-selector {
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  color: #6e6b7b;

  .opp-selector {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 9px 35px 9px 12px;
    overflow: hidden;
    cursor: pointer;

    .overflow-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
    }

    .remove-opp {
      position: absolute;
      right: 8px;
      color: #6e6b7b;
      cursor: pointer;
    }
  }

  .opp-selected {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 9px 50px 9px 12px;
    gap: 5px;

    .marginless {
      margin: 0;
    }

    .texts-holder {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      .overflow-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
      }
    }

    #drop-actions {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 8px;
      color: #ffffff;
      cursor: pointer;

      .drop-header {
        text-align: center;
        padding: 10px 0;
        border-radius: 5px 5px 0 0;
        background: #d4e2f3;
        color: #046af3;
      }
    }

    :deep(#drop-actions) {
      user-select: none;

      #drop-actions__BV_toggle_ {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        padding: 0;
        border-radius: 5px;
        border: none;
        background: #046af3 !important;
      }

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-menu {
        padding-top: 0;
        border-radius: 5px;
        cursor: default;
      }

      .dropdown-item {
        display: flex;
        align-items: center;
        gap: 10px;

        i {
          color: #046af3;
        }
      }
    }
  }
}

.task-opp-selector-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100dvh;
  width: 100%;
  overflow: hidden;

  .task-opp-selector-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid #ececec;
    color: #31333f;

    .modal-title {
      font-size: 15px;
      font-weight: 600;
    }

    .icon-close-circle-outline {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .list-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 1.5rem 1.5rem 1.5rem;
    margin-top: 1.5rem;
    overflow-x: hidden;
    overflow-y: auto;

    .search-box {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 100;
      padding: 10px;
      gap: 10px;
      border: 1px solid #ececec;
      background: #ffffff;

      input {
        flex: 1;
        font-size: 16px;
        padding: 0;
        border: none;
        outline: none;
        color: #6e6b7b;
      }
    }

    .opportunity-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px;
      gap: 10px;
      border: 1px solid #ececec;
      border-top: none;
      cursor: pointer;

      .opp-info {
        display: flex;
        flex-direction: column;
        width: 100%;

        .opp-name {
          font-size: 16px;
          line-height: 24px;
          color: #4c5862;
        }

        .opp-workspace {
          font-size: 13px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: #999999;
        }
      }

      &:hover {
        background: #f5f5f5;
      }
    }
  }

  .list-holder > * {
    flex-shrink: 0;
  }

  .spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
  }

  .bottom-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    margin-top: auto;
    border-top: 1px solid #ececec;
    color: #000000;
    background: #ffffff;
  }
}
</style>
