import moment from "moment";
import { createPopper } from "@popperjs/core";
import store from "@/store";

moment.locale("pt-BR");
moment.updateLocale("pt-br", {
  calendar: {
    lastDay: "[Ontem]",
    sameDay: "HH:mm",
    nextDay: "DD/MM/YY",
    lastWeek: "ddd",
    nextWeek: "DD/MM/YY",
    sameElse: "DD/MM/YY",
  },
});

export default {
  imUrls: [
    "crm.imlabs.local:8080",
    "crm.imlabs.com.br",
    "crm.sandbox.imlabs.com.br",
    "crm.homolog.imlabs.com.br",
    "comercial.imlabs.com.br",
    "crm.expansao.institutomix.com.br",
    "rh.institutomix.com.br",
  ],

  isIm() {
    const url = window.location.host;

    return this.imUrls.includes(url);
  },

  formattedHtmlText(text) {
    if (!text) return "";

    return text.replace(/\n/g, "<br>");
  },

  toUpperCase(str) {
    return str.toUpperCase();
  },

  checkSort(check, sort) {
    if (!check || !sort) return false;

    const startsWithHyphen = sort.startsWith("-");

    if (startsWithHyphen) return check === sort.slice(1);

    return check === sort;
  },

  getSortIcon(srt) {
    if (!srt) return "";

    const startsWithHyphen = srt.startsWith("-");

    if (startsWithHyphen) return "icon-keyboard-arrow-down";

    return "icon-keyboard-arrow-up";
  },

  isBoolean(value) {
    return typeof value === "boolean";
  },

  formatBrlAmountSymbol(amount) {
    const price = amount || 0;

    return parseFloat(price).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  },

  formatBrlAmountNoSymbol(amount) {
    return parseFloat(amount).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });
  },

  getMonth(data, format) {
    return moment(data, format).format("M");
  },

  getStartDateMonth(month, format) {
    return moment(month, format).startOf("month").format("YYYY-MM-DD");
  },

  getEndDateMonth(month, format) {
    return moment(month, format).endOf("month").format("YYYY-MM-DD");
  },

  getDateDay(date, day) {
    return moment(date).add(day, "day").format("YYYY-MM-DD");
  },

  dateFormat(date, currentFormat = "YYYY-MM-DD", toFormat = "YYYY-MM-DD") {
    return moment(date, currentFormat).format(toFormat);
  },

  dateFormatFromTimestamp(date, toFormat = "YYYY-MM-DD") {
    return moment.unix(date).format(toFormat);
  },

  chatDateFormat(date) {
    return moment(date).subtract().calendar();
  },

  isEmptyObject(object) {
    return Object.values(object).length === 0;
  },

  isNullOrUndefined(value) {
    return value === null || value === undefined;
  },

  splitFirstName(completedName) {
    if (!completedName) return completedName;

    return completedName.split(" ")[0];
  },

  getFirstLetter(str) {
    if (!str) return "";

    return str.substr(0, 1);
  },

  isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  },

  isBlank(value) {
    return value === "";
  },

  toCapitalize(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  },

  formatQueryParam(queryParamsArr) {
    let formattedQuery = "?";

    queryParamsArr.forEach((item) => {
      if (item.param != null && item.param !== "") {
        formattedQuery += `${!formattedQuery.endsWith("?") ? "&" : ""}${item.query}=${item.param}`;
      }
    });

    return formattedQuery;
  },

  randomKey() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  },

  colorHashFromString(str) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let colour = "#";

    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }

    return colour;
  },

  getStartTimestampForTheQuery(startTimestamp) {
    let selectedStartTimestamp;

    if (startTimestamp) {
      selectedStartTimestamp = `${moment(startTimestamp.replaceAll("-", "/")).format(
        "DD/MM/YYYY"
      )} 00:00:00`;

      selectedStartTimestamp = moment(selectedStartTimestamp, this.defaultDateFormat)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
    }

    return selectedStartTimestamp;
  },

  getEndTimestampForTheQuery(endTimestamp) {
    let selectedEndTimestamp;

    if (endTimestamp) {
      selectedEndTimestamp = `${moment(endTimestamp.replaceAll("-", "/")).format(
        "DD/MM/YYYY"
      )} 00:00:00`;

      selectedEndTimestamp = moment(selectedEndTimestamp, this.defaultDateFormat)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
    }

    return selectedEndTimestamp;
  },

  resetObjectFieldsToUndefined(obj) {
    if (!obj) return;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = undefined;
      }
    }
  },

  checkExportPermission(role) {
    const possibleRoles = {
      admin: true,
      manager: true,
      auxiliarTi: true,
      consultorFranquia: true,
      gestorFranquia: true,
      diretorFranquia: true,
      consultorComercial: true,
      diretorComercial: true,
      gerenteComercial: true,
      franqueado: true,
      master: true,
      ceo: true,
    };

    return possibleRoles[role] || false;
  },

  generateRandomColorContrastedWithWhite() {
    const r = Math.floor(Math.random() * 225);
    const g = Math.floor(Math.random() * 225);
    const b = Math.floor(Math.random() * 225);

    const color = ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");

    return `#${color}`;
  },

  generateVividColorHex(usedColors = []) {
    const getRandomColor = () => {
      const r = Math.floor(Math.random() * 155) + 100;
      const g = Math.floor(Math.random() * 155) + 100;
      const b = Math.floor(Math.random() * 155) + 100;
      return { r, g, b, hex: `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}` };
    };

    const colorDistance = (color1, color2) => {
      return Math.sqrt(
        Math.pow(color1.r - color2.r, 2) +
          Math.pow(color1.g - color2.g, 2) +
          Math.pow(color1.b - color2.b, 2)
      );
    };

    let newColor;
    let attempts = 0;
    const minDistance = 100;

    do {
      newColor = getRandomColor();
      attempts++;
    } while (
      usedColors.some((hex) => {
        const [r, g, b] = hex.match(/\w\w/g).map((c) => parseInt(c, 16));
        return colorDistance(newColor, { r, g, b }) < minDistance;
      }) &&
      attempts < 50 // Evita um loop infinito
    );

    return newColor.hex;
  },

  convertTimeToSeconds(time, format) {
    if (!time) return 0;

    const parsedTime = moment(time, format);

    const hours = parsedTime.hours();
    const minutes = parsedTime.minutes();
    const seconds = parsedTime.seconds();

    return hours * 3600 + minutes * 60 + seconds;
  },

  convertSecondsToTime(seconds) {
    if (!seconds) return "00:00:00";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes}:${remainingSeconds}`;
  },

  getPercentage(totalValue, partialValue) {
    if (!totalValue || !partialValue) return 0;

    return ((partialValue / totalValue) * 100).toFixed(2);
  },

  generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  withPopper(dropdownList, component, { width }) {
    dropdownList.style.width = width;

    const popper = createPopper(component.$refs.toggle, dropdownList, {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, -1],
          },
        },
        {
          name: "toggleClass",
          enabled: true,
          phase: "write",
          fn({ state }) {
            component.$el.classList.toggle("drop-up", state.placement === "top");
          },
        },
      ],
    });

    return () => popper.destroy();
  },

  getDefaultDateMessageByRole(role, htmlFormat = false) {
    const defaultDateByRole = store.getters["userStore/getDefaultDateByRole"];

    const message = window.innerWidth < 800 ? "Dias " : "Resultado dos dias ";
    const roleCase = defaultDateByRole[role] || "24-hours";

    const today = moment().format("DD/MM/YYYY");

    if (roleCase == "3-months") {
      const threeMonthsAgo = moment().subtract(3, "months").format("DD/MM/YYYY");

      if (htmlFormat)
        return `<div> ${message} <b> ${threeMonthsAgo} </b> a <b> ${today} </b> </div>`;

      return message + threeMonthsAgo + " a " + today;
    }

    if (roleCase == "1-month") {
      const oneMonthAgo = moment().subtract(1, "month").format("DD/MM/YYYY");

      if (htmlFormat) return `<div> ${message} <b> ${oneMonthAgo} </b> a <b> ${today} </b> </div>`;

      return message + oneMonthAgo + " a " + today;
    }

    if (roleCase == "24-hours") {
      const yesterday = moment().subtract(1, "day").format("DD/MM/YYYY");

      if (htmlFormat) return `<div> ${message} <b> ${yesterday} </b> a <b> ${today} </b> </div>`;

      return message + yesterday + " a " + today;
    }
  },

  getIconByOpportunityType(opType) {
    if (!this.isIm()) return null;

    const types = {
      1: null,
      2: "BriefcaseIcon",
      3: "BookOpenIcon",
      4: "TruckIcon",
    };

    return types[opType] || null;
  },

  darkenHexColor(hex, amount) {
    if (!hex) return null;

    hex = hex.replace("#", "");

    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    r = Math.max(0, Math.min(255, Math.floor(r * (1 - amount))));
    g = Math.max(0, Math.min(255, Math.floor(g * (1 - amount))));
    b = Math.max(0, Math.min(255, Math.floor(b * (1 - amount))));

    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
      .toString(16)
      .padStart(2, "0")}`;
  },

  getContrastColor(hex) {
    if (!hex) return null;

    hex = hex.replace("#", "");

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const luminosity = (r * 0.299 + g * 0.587 + b * 0.114) / 255;

    return luminosity > 0.5 ? "#000000" : "#FFFFFF";
  },

  findLast(array, callback) {
    return array.reduceRight((result, current) => {
      if (result !== undefined) return result;

      return callback(current) ? current : undefined;
    }, undefined);
  },

  clearPhoneNumber(phone) {
    if (!phone) return phone;

    return phone.replace(/\D/g, "");
  },

  formattedHtmlTextMessage(text) {
    if (!text) return "";

    let newText = text.replace(/\n/g, "<br>");

    const emailPlaceholders = [];

    newText = newText.replace(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g, (match) => {
      const placeholder = `__EMAIL_PLACEHOLDER_${emailPlaceholders.length}__`;
      emailPlaceholders.push(match);
      return placeholder;
    });

    const tld =
      "(com|net|org|gov|edu|br|io|tech|info|me|ai|co|biz|xyz|us|uk|ca|au|eu|in|de|fr|jp|ru|cn|es|it|nl|se|no|dk|fi|ch|pl|be|ar|mx|za|cl|pt|hk|id|my|ph|sg|th|vn|si)";

    const urlRegex = new RegExp(
      "\\b(?:(?:https?:\\/\\/|www\\.)?[A-Za-z0-9-]+(?:\\.[A-Za-z0-9-]+)*\\." +
        tld +
        ")(?:\\/[\\w\\-./?=#%&]*)?\\b",
      "gi"
    );

    newText = newText.replace(urlRegex, (match) => {
      let url = match;
      if (!/^https?:\/\//i.test(match) && !match.startsWith("www.")) {
        url = `https://${match}`;
      }
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="omni-message-link">${match}</a>`;
    });

    emailPlaceholders.forEach((email, idx) => {
      const placeholder = `__EMAIL_PLACEHOLDER_${idx}__`;

      newText = newText.replace(
        placeholder,
        `<a href="mailto:${email}" class="omni-message-link">${email}</a>`
      );
    });

    return newText;
  },

  formatPhoneNumber(phoneNumberString) {
    if (!phoneNumberString) return phoneNumberString;

    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let pattern = /^(\d{2})?(\d{2})(\d{4,5})(\d{4})$/;
    let match = pattern.exec(cleaned);

    if (match) {
      let intlCode = match[1] ? "+55 " : "";

      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return phoneNumberString;
  },

  getMultipleFilters(filter, queryName, array) {
    filter.forEach((element) => {
      array.push({
        query: queryName + "[]",

        param: element,
      });
    });
  },
};
