<template>
  <div
    @click="$bvModal.show('opportunity-step-sales-stage-selector')"
    :style="{
      borderColor: $service.darkenHexColor(currentColor, 0.25),
      color: $service.getContrastColor(currentColor, 0.25),
      backgroundColor: '#' + currentColor,
    }"
    class="step-holder"
  >
    Etapa: {{ currentStep?.name }}

    <b-modal id="opportunity-step-sales-stage-selector" hide-footer hide-header>
      <sales-stage-selector
        @select="selectStage($event)"
        @close="$bvModal.hide('opportunity-step-sales-stage-selector')"
        :sales-stages="creationStagesOnly ? openingStages : salesStages"
      />
    </b-modal>

    <b-modal
      v-if="opportunity"
      id="ruled-stage-change-task-form"
      class="modal-dialog"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <task-form
        @saved="handleTaskSave($event)"
        @close="$bvModal.hide('ruled-stage-change-task-form')"
        :opportunity="opportunity"
        register-only
      />
    </b-modal>
  </div>
</template>

<script>
import SalesStageSelector from "@/views/components/SalesStageSelector.vue";
import TaskForm from "@/views/forms/TaskForm.vue";

export default {
  components: {
    SalesStageSelector,
    TaskForm,
  },

  props: {
    salesStages: {
      type: Array,
      default: () => [],
    },

    opportunity: {
      type: Object,
      default: null,
    },

    creationStagesOnly: {
      type: Boolean,
      default: false,
    },

    ruledStageChange: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      toBeChangedStage: null,
    };
  },

  computed: {
    currentStep() {
      return this.salesStages?.find((stage) => stage.id === this.value);
    },

    currentColor() {
      return this.currentStep?.color;
    },

    openingStages() {
      return this.salesStages?.filter((stage) => stage.type == "First" || stage.type == "PreSale");
    },

    workspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },
  },

  methods: {
    selectStage(stage) {
      if (this.ruledStageChange) {
        this.checkStageChange(stage);

        return;
      }

      this.$emit("input", stage.id);
    },

    checkStageChange(stage) {
      this.toBeChangedStage = stage;

      if (stage.id == this.value) return;

      if (!this.opportunity.task || !this.opportunity.task?.count) {
        this.$bvModal.show("ruled-stage-change-task-form");

        this.$bvToast.toast(
          "Uma oportunidade deve ter ao menos uma tarefa para poder mudar de etapa!",
          {
            title: `Oportunidade sem tarefa!`,
            autoHideDelay: 2500,
            variant: "warning",
            toaster: "b-toaster-top-left",
            solid: true,
          }
        );

        return;
      }

      this.$emit("input", stage.id);
    },

    handleTaskSave() {
      this.$emit("updateTaskCount");

      this.$bvModal.hide("ruled-stage-change-task-form");

      this.$emit("input", this.toBeChangedStage.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.step-holder {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  border: 2px solid #e0e0e0;
  cursor: pointer;
}
</style>
